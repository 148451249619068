body {
  margin: 0;
  background: #000;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* unvisited link */
a:link {
  color: white;
}

/* visited link */
a:visited {
  color: #eee;
}

/* mouse over link */
a:hover {
  color: #ccf;
}

/* selected link */
a:active {
  color: #ccc;
}

input, textarea, button {
  outline-color: #acb;
}

::selection {
  background: #acb;
}